import { useEffect } from 'react';

const Widget = props => {

    const { attributes, isBackend, custom = {} } = props;
    const { options, tracks } = attributes;

    const { isAutoPlay, color, isBuying, isSharing, isDownload, isArtWork, isPlayCount, isUser, isSingleActive, isComments, isVisual, isTeaser, isHideRelated } = options;

    return tracks.map((track, index) => {
        return <div key={index} className={`mainWidget ${isBackend && custom.activeIndex === index ? 'scbNowEditing' : ''}`} onClick={() => isBackend ? custom.setActiveIndex(index) : null}>
            <iframe key={index} id="sc-widget" width="100%" height="100%" scrolling='no' frameBorder='no' allow="autoplay"
                src={`https://w.soundcloud.com/player/?url=${track.url}&color=%23${color.replace('#', '').substr(0, 6)}&auto_play=${isAutoPlay}&buying=${isBuying}&sharing=${isSharing}&download=${isDownload}&show_artwork=${isArtWork}&show_playcount=${isPlayCount}&show_user=${isUser}&single_active=${isSingleActive}&show_comments=${isComments}&visual=${isVisual}&show_teaser=${isTeaser}&hide_related=${isHideRelated}&start_track=${track.startTrack}`}></iframe>
        </div>
    })
}
export default Widget;