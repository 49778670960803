import { createRoot } from 'react-dom/client';

import './style.scss';
import Style from './Style';
import Widget from './Widget';

// Sound Cloud
document.addEventListener('DOMContentLoaded', () => {
	const allSoundCloud = document.querySelectorAll('.wp-block-scb-sound-cloud');

	allSoundCloud.forEach(soundCloud => {
		const attributes = JSON.parse(soundCloud.dataset.attributes);

		createRoot(soundCloud).render(<>
			<Style attributes={attributes} clientId={attributes.cId} />
			<Widget attributes={attributes} clientId={attributes.cId} />
		</>);

		soundCloud?.removeAttribute('data-attributes');
	});
});